@import '~bootstrap/scss/bootstrap.scss';
.bg-mcolor {
  background-color: var(--bs-green) !important;
}

.logo{
  width: 75px;
  height: auto;
}

.page-header{
  background-color: white;
  min-height: 115px;
}

.page-block-min{
  background-color: white;
  min-height: 150px;
}

.btn-cntrl-collapse {
  background-color: var(--bs-green) !important;
  color: white;
  width: 75px;
  height: 75px;
  border-radius: 37px;
  border: none;
  left: 85% ;
}

.btn-cntrl-collapsed {
  z-index: 2 !important;
  left: 40%;
}

.main-content{
  width: 81% !important;
}

.main-content-collapsed{
  width: 92% !important;
}

@media (max-width: map-get($grid-breakpoints, "lg")) {
  .main-content{
    width: 100% !important;
  }

  .main-content-collapsed{
    width: 100% !important;
  }

}