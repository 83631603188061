.fix-header-table{
  overflow: scroll;
  height: 800px;

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background-color: #fff;
  }

  tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  thead th:first-child {
    left: 0;
    z-index: 1;
  }
}